<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xsOnly"
    v-model="show"
    max-width="500px"
    max-height="800px"
    scrollable
    persistent
  >
    <div class="text-center" v-if="loading" style="min-height: 500px">
      <v-progress-circular
        :size="100"
        :width="10"
        style="
          text-align: center;
          padding-top: 20px;
          padding-bottom: 20px;
          margin-top: 200px;
        "
        color="#7253CF"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-card v-else>
      <v-card-title
        class="pb-0 text-center"
        style="background-color: #7253cf; color: white"
      >
        <img
          src="@/assets/basketLogoDialogDistrictManagement.svg"
          width="120"
        />

        <div class="ml-7 dialogTitle-text">Edit School Public Page</div>
        <v-spacer></v-spacer>
        <v-icon
          text
          large
          color="white"
          @click="toggleEditSchoolModal({ show: false })"
          style="cursor: pointer; margin-top: -185px; margin-left: 90%"
          >mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="pt-3">
        <v-form lazy-validation ref="schoolForm">
          <v-row style="border-bottom: 1px solid #929292" class="px-4">
            <v-col
              cols="6"
              sm="3"
              md="4"
              style="border-right: 1px solid #929292"
            >
              <div class="profilePicture">
                <div class="profilePictureImage d-flex justify-center">
                  <label for="inputId" ref="imgRef" style="cursor: pointer">
                    <div v-if="!previewImage">
                      <img
                        src="@/assets/publicDonationPage/createStudent/defaultProfile.svg"
                        width="95"
                        alt=""
                      />
                      <img
                        src="@/assets/publicDonationPage/createStudent/plus.svg"
                        width="20"
                        alt=""
                        style="position: relative; right: 28px"
                      />
                    </div>
                    <div v-else>
                      <img
                        :src="previewImage"
                        alt=""
                        width="110"
                        style="max-height: 100px; min-height: 100px"
                      />
                    </div>
                  </label>
                </div>
                <div
                  class="profilePictureInstruction text-center"
                  @click="$refs.imgRef.click()"
                >
                  <span v-if="!previewImage">Upload Logo</span>
                  <span v-else>Change Logo</span>
                </div>
              </div>
              <!-- <v-file-input
                id="inputId"
                style="display: none"
                v-model="imgFile"
                :disabled="formLoading"
                @change="previewProfilePhoto"
                accept="image/*,video/*"
                capture="user,environment"
              > 
              </v-file-input> -->
              <input
                id="inputId"
                ref="fileInput"
                type="file"
                style="display: none"
                accept="image/*"
                :disabled="formLoading"
                @change="previewProfilePhoto"
              />
            </v-col>
            <v-col cols="6" sm="3">
              <label
                for="inputIds"
                class="labelGallery d-flex justify-center text-center"
              >
                <div class="assetsGallery" v-if="!fileLoader">
                  <div>
                    <img
                      src="@/assets/publicDonationPage/createStudent/galleryPlus.svg"
                      width="25"
                      alt=""
                    />
                  </div>
                  <div style="color: #2c1963">
                    Upload Photos & Videos for Gallery
                  </div>
                </div>
                <div class="assetsGallery" v-else>
                  <h3 style="color: #2c1963; font-size: 13px">
                    Uploading {{ fileCount }}/{{ fileLenght }}
                  </h3>
                  <p style="color: #404040; font-size: 10px">
                    {{ progressPercentage }}%
                  </p>
                  <div class="px-6" style="margin-top: -15px">
                    <v-progress-linear
                      color="#7253CF"
                      indeterminate
                      class="py-0"
                      height="25"
                    ></v-progress-linear>
                  </div>
                </div>
              </label>

              <input
                type="file"
                multiple
                style="display: none"
                id="inputIds"
                ref="inputGallery"
                accept="image/*,video/*"
                :disabled="fileLoader"
                @change="uploadAssets"
              />
            </v-col>
            <v-col cols="12" sm="6" v-if="assestStoreList[0]">
              <v-sheet
                class="mx-auto"
                max-width="100%"
                min-height="100%"
                color="#f7f7f7"
              >
                <v-slide-group
                  multiple
                  :show-arrows="$vuetify.breakpoint.smAndUp"
                >
                  <template v-slot:prev>
                    <v-icon color="#D30024">mdi-chevron-left</v-icon>
                  </template>
                  <template v-slot:next>
                    <v-icon color="#D30024">mdi-chevron-right</v-icon>
                  </template>
                  <v-slide-item
                    v-for="(item, i) in assestStoreList"
                    :key="i"
                    class="mx-3"
                  >
                    <div class="imageVideoDiv" v-if="item.type === 'image'">
                      <div style="height: 100%">
                        <img
                          :src="item.file_url"
                          alt=""
                          style="
                            max-width: 110px;
                            max-height: 110px;
                            border-radius: 5px;
                          "
                        />
                      </div>
                      <div class="deleteBTNDiv" @click="deleteAssets(item.id)">
                        <v-icon size="18" color="red">mdi-delete</v-icon>
                      </div>
                    </div>
                    <div class="imageVideoDiv" v-else>
                      <video
                        :src="item.file_url"
                        style="
                          width: 110px;
                          height: 110px;
                          object-fit: cover;
                          border-radius: 5px;
                        "
                      ></video>
                      <img
                        src="@/assets/play.png"
                        width="30"
                        style="position: relative; bottom: 35%; right: 60%"
                      />
                      <div class="deleteBTNDiv" @click="deleteAssets(item.id)">
                        <v-icon size="18" color="red">mdi-delete</v-icon>
                      </div>
                    </div>
                  </v-slide-item>
                </v-slide-group>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pr-2">
              <v-text-field
                outlined
                dense
                v-model="schoolName"
                :rules="[rules.required]"
                label="School name"
                class="formFields"
                color="#7253CF"
                :disabled="formLoading"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="pl-2">
              <v-text-field
                outlined
                dense
                v-model="contactPersonName"
                label="Contact person name"
                color="#7253CF"
                class="formFields"
                :rules="[rules.required]"
                :disabled="formLoading"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6" class="pr-2">
              <v-text-field
                label="Email"
                outlined
                dense
                class="formFields"
                :rules="[rules.required, rules.email]"
                v-model="email"
                color="#7253CF"
                :disabled="formLoading"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="pl-2">
              <v-text-field
                label="Mobile"
                outlined
                dense
                class="formFields"
                v-model="mobile"
                type="number"
                :rules="[rules.mobile]"
                color="#7253CF"
                :disabled="formLoading"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                v-model="role"
                label="Role"
                color="#7253CF"
                class="formFields"
                :rules="[rules.required]"
                :disabled="formLoading"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                outlined
                dense
                v-model="shippingAddress"
                :rules="[rules.required]"
                label="Shipping Address"
                color="#7253CF"
                class="formFields"
                row-height="8"
                :disabled="formLoading"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          class="rounded-lg dialogAction-btnText px-15"
          height="43px"
          dark
          color="#38227A"
          :loading="formLoading"
          @click="submitForm"
        >
          <span>Save</span></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import RULES from "@/common/fieldRules";
import Axios from "@/api/BaseAxios";
import {
  API_ADMIN_ADD_CAMPAIGN_IMAGE,
  API_ADMIN_GET_SCHOOL_DETAIL,
} from "@/constants/APIUrls";
export default {
  name: "DistrictUpdateModal",
  data() {
    return {
      rules: RULES,
      loading: false,
      formLoading: false,
      imgFile: {},
      previewImage: null,
      imgAssets: [],
      assestStoreList: [],
      fileLoader: false,
      fileCount: 0,
      fileLenght: null,
      schoolName: "",
      contactPersonName: "",
      email: "",
      mobile: null,
      role: "",
      shippingAddress: "",
    };
  },
  computed: {
    ...mapGetters({
      getShow: "schoolManagement/getEditShow",
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    show: {
      get() {
        return this.getShow;
      },
      set(value) {
        return this.toggleEditSchoolModal({ show: value });
      },
    },
    progressPercentage() {
      return Math.trunc((this.fileCount / this.fileLenght) * 100);
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleEditSchoolModal: "schoolManagement/toggleUpdateModal",
      showToast: "snackBar/showToast",
    }),
    openModal() {
      console.log(this.selectedCampaign);
      this.getSchoolDetails();
    },
    closeModal() {
      this.loading = false;
      this.formLoading = false;
      this.imgFile = {};
      this.previewImage = null;
      this.imgAssets = [];
      this.assestStoreList = [];
      this.fileLoader = false;
      (this.fileLenght = null), (this.fileCount = 0);
      this.schoolName = "";
      this.contactPersonName = "";
      this.email = "";
      this.mobile = null;
      this.role = "";
      this.shippingAddress = "";
    },
    previewProfilePhoto(payload) {
      console.log(payload, "payload");
      // let file = payload;
      // this.imgFile = payload;
      // if (file) {
      //   this.previewImage = URL.createObjectURL(file);
      //   URL.revokeObjectURL(file);
      // } else {
      //   this.previewImage = null;
      // }

      let input = this.$refs.fileInput;
      let file = input.files;
      this.imgFile = file[0];
      console.log(this.imgFile);
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      } else {
        this.previewImage = null;
      }
    },
    uploadAssets() {
      let input = this.$refs.inputGallery;
      let file = input.files;
      console.log(file, "files");
      this.imgAssets = file;

      this.fileCount = 0;
      this.fileLenght = Object.keys(this.imgAssets).length;
      if (this.fileLenght) this.fileLoader = true;
      for (const obj of this.imgAssets) {
        const successHandler = (res) => {
          console.log(res.data);
          let img = res.data.campaign_assests_detail;
          this.assestStoreList.push({
            file: img.file,
            file_url: img.file_url,
            id: img.id,
            type: img.type,
          });
          this.fileCount = this.fileCount + 1;
          if (this.fileCount === this.fileLenght) this.fileLoader = false;
        };
        const failureHandler = (res) => {
          console.log(res);
          this.fileLoader = false;
        };
        let formData = new FormData();
        console.log(obj);
        formData.append("file", obj);
        formData.append("type", obj.type.split("/")[0]);
        formData.append("campaign", this.selectedCampaign.campaignData.id);
        formData.append("school", this.selectedCampaign.organizationData.id);
        Axios.request_POST(
          API_ADMIN_ADD_CAMPAIGN_IMAGE,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
    },
    deleteAssets(ID) {
      console.log(ID);
      const successHandler = (res) => {
        console.log(res);
        this.assestStoreList.splice(
          this.assestStoreList.findIndex((item) => item.id == ID),
          1
        );
        this.showToast({
          message: "Deleted",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Something went wrong",
          color: "e",
        });
      };
      let formData = {};
      formData["assests_id"] = ID;
      Axios.request_DELETE(
        API_ADMIN_ADD_CAMPAIGN_IMAGE,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getSchoolDetails() {
      const self = this;
      self.loading = true;
      const successHandler = (res) => {
        self.assestStoreList = res.data.assests_list;
        self.previewImage = res.data.school_detail.logo_url;
        self.schoolName = res.data.school_detail.school_name;
        self.contactPersonName = res.data.school_detail.user_name;
        self.email = res.data.school_detail.user_email;
        self.mobile = res.data.school_detail.user_phone;
        self.role = res.data.school_detail.role;
        self.shippingAddress = res.data.school_detail.shipping_address;
        self.loading = false;
      };

      const failureHandler = (res) => {
        console.log("Failure ", res);
        self.loading = false;
      };

      let formData = {};
      formData["campaign_id"] = this.selectedCampaign.campaignData.id;
      formData["school_id"] =
        this.$store.state.schoolManagement.schoolUpdateModal.schoolID;
      /**
       * API Call for ADD District
       */
      Axios.request_GET(
        API_ADMIN_GET_SCHOOL_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitForm() {
      this.formLoading = true;
      const successHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "School detail updated.",
          color: "s",
        });
        this.toggleEditSchoolModal({ show: false });
        this.$emit("reload");
        this.formLoading = false;
      };
      const failureHandler = (res) => {
        this.showToast({
          message: res,
          color: "e",
        });
        this.formLoading = false;
      };
      let formData = new FormData();
      formData.append("campaign_id", this.selectedCampaign.campaignData.id);
      formData.append(
        "school_id",
        this.$store.state.schoolManagement.schoolUpdateModal.schoolID
      );
      formData.append("school_name", this.schoolName);
      formData.append("user_name", this.contactPersonName);
      formData.append("role", this.role);
      formData.append("shipping_address", this.shippingAddress);
      formData.append("user_email", this.email);
      formData.append("user_phone", this.mobile);
      if (this.imgFile instanceof File) {
        formData.append("logo", this.imgFile);
      }
      if (this.assestStoreList[0]) {
        formData.append("assests_list", JSON.stringify(this.assestStoreList));
      }
      Axios.request_PATCH(
        API_ADMIN_GET_SCHOOL_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted() {},
};
</script>
<style scoped>
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 315%;
  color: #ffffff;
}
.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.profilePictureInstruction {
  color: #2c1963;
  letter-spacing: 0px;
  cursor: pointer;
}
.profilePictureInstruction >>> span {
  border-bottom: 1px solid #2c1963;
}
.profilePictureImage {
  cursor: pointer;
}
.assetsGallery {
  width: 100%;
  margin-top: 25%;
  justify-items: center;
}
.labelGallery {
  border: 1px dashed #2c1963;
  width: 100%;
  height: 100%;
  background: rgba(44, 25, 99, 0.1);
  cursor: pointer;
}
.imageVideoDiv {
  width: 110px;
  height: 110px;
  margin-top: 6px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
}
.deleteBTNDiv {
  height: 25px;
  width: 25px;
  background: white;
  position: relative;
  bottom: 100%;
  right: 2%;
  display: flex;
  padding-left: 3px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  float: right;
  cursor: pointer;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .modalTitle {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 219%;
    color: #ffffff;
  }
  .v-btn >>> span {
    font-size: 14px;
    font-weight: 600;
  }
  .deleteBTNDiv {
    height: 25px;
    width: 25px;
    background: white;
    position: relative;
    bottom: 100%;
    right: 2%;
    display: flex;
    padding-left: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    float: right;
    cursor: pointer;
  }
}
</style>
